import React, { useState, useEffect } from "react";
import { ClockLoader } from "react-spinners";
// import axios from "axios";
// import spinnerImg from "../images/Spin-1s-200px.gif";
import aiPlaceholderImage from "../images/momo-ai-placeholder-512x512.jpg";
import uploadPlaceholderImage from "../images/momo-upload-placeholder-512x512.jpg";
// import ImageResizer from "browser-image-resizer";
// import { readAndCompressImage } from "browser-image-resizer";
import petPromptsData from "./petPrompts.json";

function ImageUpload() {
  const fileInputRef = React.createRef();

  const [selectedImageDisplay, setSelectedImageDisplay] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [magicImage, setMagicImage] = useState();
  const [base64Image, setBase64Image] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [aiPlaceholderURL, setAiPlaceholderURL] = useState();
  const [uploadPlaceholderURL, setUploadPlaceholderURL] = useState();

  const [promptData, setPromptData] = useState(null);

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [selectedPromptIndex, setSelectedPromptIndex] = React.useState(0);

  const engineId = "stable-diffusion-xl-beta-v2-2-2";
  const apiHost = process.env.API_HOST ?? "https://api.stability.ai";
  const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

  useEffect(() => {
    async function fetchData() {
      const aiPlaceholder = await fetch(aiPlaceholderImage);
      const aiPlaceholderBlob = await aiPlaceholder.blob();
      setAiPlaceholderURL(URL.createObjectURL(aiPlaceholderBlob));

      const uploadPlaceholder = await fetch(uploadPlaceholderImage);
      const uploadPlaceholderBlob = await uploadPlaceholder.blob();
      setUploadPlaceholderURL(URL.createObjectURL(uploadPlaceholderBlob));

      setPromptData(petPromptsData);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (base64Image && base64Image.trim() !== "") {
      const byteCharacters = atob(base64Image);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      const blobUrl = URL.createObjectURL(blob);
      setIsLoading(false);
      setMagicImage(blobUrl);
    }
  }, [base64Image]);

  const handleUploadPetImageButtonClick = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };

  const handleImageSelection = async (event) => {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setSelectedImageDisplay(imageURL);

    const image = new Image();
    image.src = imageURL;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // these will be the dimensions of the final image
      let width = 768;
      let height = 512;

      // calculate scale based on the aspect ratio
      const aspectRatio = image.width / image.height;

      // check if image is near square
      if (Math.abs(1 - aspectRatio) <= 0.1) {
        width = height = 512;
      }

      // check if image is in portrait orientation
      if (aspectRatio < 1) {
        width = 512;
        height = 768;
      }

      const desiredAspectRatio = width / height;

      let newWidth, newHeight;

      if (aspectRatio > desiredAspectRatio) {
        // if image is wider than desired aspect ratio, set height and scale width
        newHeight = height;
        newWidth = image.width * (newHeight / image.height);
      } else {
        // if image is taller than desired aspect ratio, set width and scale height
        newWidth = width;
        newHeight = image.height * (newWidth / image.width);
      }

      // adjust drawing coordinates to center new image
      const xOffset = (newWidth - width) / 2;
      const yOffset = (newHeight - height) / 2;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, -xOffset, -yOffset, newWidth, newHeight);

      if (canvas) {
        canvas.toBlob(function (blob) {
          setSelectedImage(blob);
          setMagicImage(null);
        }, "image/jpeg");
      }

      // const dataUrl = canvas.toDataURL();
      // Do something with dataUrl
      // console.log(dataUrl);
      // setCroppedImageURL(dataUrl);
    };
  };

  const handleMakeMagicAiImageButtonClick = async () => {
    setIsLoading(true);
    // const spinner = await fetch(spinnerImg);
    // const spinnerBlob = await spinner.blob();

    // setMagicImage(URL.createObjectURL(spinnerBlob));

    const formData = new FormData();
    formData.append("init_image", selectedImage);

    const petPrompt =
      promptData[selectedItemIndex].prompts[selectedPromptIndex];

    formData.append("text_prompts[0][text]", petPrompt.text_prompts_0_text);
    formData.append("text_prompts[0][weight]", petPrompt.text_prompts_0_weight);

    formData.append("text_prompts[1][text]", petPrompt.text_prompts_1_text);
    formData.append("text_prompts[1][weight]", petPrompt.text_prompts_1_weight);

    formData.append("init_image_mode", petPrompt.init_image_mode);
    formData.append("image_strength", petPrompt.image_strength);
    formData.append("cfg_scale", petPrompt.cfg_scale);
    formData.append("samples", petPrompt.samples);
    formData.append("steps", petPrompt.steps);
    formData.append("style_preset", petPrompt.style_preset);
    formData.append("clip_guidance_preset", petPrompt.clip_guidance_preset);

    const response = await fetch(
      `${apiHost}/v1/generation/${engineId}/image-to-image`,
      {
        method: "POST",
        headers: {
          //   ...formData.getHeaders(),
          Accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`Non-200 response: ${await response.text()}`);
    }

    const responseJSON = await response.json();

    responseJSON.artifacts.forEach((image, index) => {
      // console.log(`${index}.png`);
      // console.log(`image.base64:`, image.base64);
      setBase64Image(image.base64);
      //   setMagicImage(Buffer.from(image.base64, "base64"));
      //   fs.writeFileSync(
      //     `out/v1_img2img_${index}.png`,
      //     Buffer.from(image.base64, 'base64')
      //   )
    });
  };

  const makeMagicDisabled = selectedImage == null;
  const selectedPrompt = promptData
    ? promptData[selectedItemIndex].prompts[selectedPromptIndex]
    : null;

  return (
    <div style={{ marginBottom: "25px", marginTop: "15px" }}>
      <div style={{ textAlign: "center" }}>
        <div style={{ paddingBottom: "10px", textAlign: "center" }}>
          {selectedImage && (
            <div style={{ paddingBottom: "3px" }}>
              <img
                style={{ width: "250px", display: "block", margin: "0 auto" }}
                src={selectedImageDisplay}
                alt="Selected"
              />
            </div>
          )}
          {!selectedImage && (
            <div style={{ paddingBottom: "3px" }}>
              <img
                style={{ width: "250px", display: "block", margin: "0 auto" }}
                src={uploadPlaceholderURL}
                alt="Selected"
              />
            </div>
          )}

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageSelection}
            style={{ width: "250px", margin: "0 auto", display: "none" }}
          />

          {/* Hide the original file input */}
          {/* <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(event) => {
              console.log(event.target.files[0]); // Do something with the selected file
            }}
          /> */}

          {/* Create your own button */}
          <button
            style={{ width: "250px", marginTop: "0px" }}
            onClick={handleUploadPetImageButtonClick}
          >
            Upload Pet Photo
          </button>
        </div>

        <div>
          {isLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "10px",

                // paddingBottom: "20px",
                // paddingTop: "25px",
                // height: "100vh",
              }}
            >
              <ClockLoader color="#36d7b7" />
            </div>
          )}
          {!isLoading && magicImage && (
            <div style={{ paddingBottom: "20px", paddingTop: "25px" }}>
              <img
                src={magicImage}
                alt="Magic"
                style={{ maxWidth: 768, width: "80%" }}
              />
            </div>
          )}
          {!isLoading && !magicImage && (
            <div style={{ paddingBottom: "20px", paddingTop: "25px" }}>
              <img
                src={aiPlaceholderURL}
                alt="Magic"
                style={{ maxWidth: "50px", width: "50px" }}
              />
            </div>
          )}

          <button
            disabled={makeMagicDisabled}
            onClick={handleMakeMagicAiImageButtonClick}
            style={{ display: "block", margin: "0 auto" }}
          >
            Make Magic AI Image
          </button>
        </div>
      </div>

      <div style={{ padding: "3px" }} />

      <div>
        <div>
          <select
            onChange={(e) => {
              const itemIndex = e.target.value;
              setSelectedItemIndex(itemIndex);
              setSelectedPromptIndex(0);
            }}
          >
            {promptData &&
              promptData.map((item, itemIndex) => (
                <option key={itemIndex} value={itemIndex}>
                  {item.pet_type}
                </option>
              ))}
          </select>
        </div>

        <div style={{ padding: "3px" }} />

        {selectedItemIndex !== -1 && (
          <div>
            <select
              value={selectedPromptIndex}
              onChange={(e) => {
                const promptIndex = e.target.value;
                setSelectedPromptIndex(promptIndex);
              }}
            >
              {promptData &&
                promptData[selectedItemIndex].prompts.map(
                  (prompt, promptIndex) => (
                    <option key={promptIndex} value={promptIndex}>
                      {prompt.key}
                    </option>
                  )
                )}
            </select>
          </div>
        )}
      </div>

      <hr />

      {selectedPrompt && (
        <div
          style={{
            textAlign: "left",
            fontSize: "12px",
            margin: "10px",
            padding: "10px",
          }}
        >
          <b>text_prompts_0_weight:</b> {selectedPrompt.text_prompts_0_weight}
          <br />
          <b>text_prompts_0_text:</b> {selectedPrompt.text_prompts_0_text}
          <br />
          <br />
          <b>text_prompts_1_weight:</b> {selectedPrompt.text_prompts_1_weight}
          <br />
          <b>text_prompts_1_text:</b> {selectedPrompt.text_prompts_1_text}
          <br />
          <br />
          <b>init_image_mode:</b> {selectedPrompt.init_image_mode}
          <br />
          <b>image_strength:</b> {selectedPrompt.image_strength}
          <br />
          <b>cfg_scale:</b> {selectedPrompt.cfg_scale}
          <br />
          <b>samples:</b> {selectedPrompt.samples}
          <br />
          <b>steps:</b> {selectedPrompt.steps}
          <br />
          <b>style_preset:</b> {selectedPrompt.style_preset}
          <br />
          <b>clip_guidance_preset:</b> {selectedPrompt.clip_guidance_preset}
          <br />
        </div>
      )}

      {/*
      // "key": "Neon-Punk Cat",
      // x"text_prompts_0_text": "A highly-detailed illustration of a stylistic cat in neon lights with dramatic lighting in a steampunk environment, cat : 1 | neon : 1 | steampunk : 1",
      // x"text_prompts_0_weight": "1",
      // x"text_prompts_1_text": "plain background, white background, lowres, text, error, cropped, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, out of frame, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, username, watermark, signature, EasyNegative, ((Nude)), ((Naked)), ((Erotica)), (((duplicate))), ((mutilated)), (((cross eyed))), [out of frame], extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), ((bad anatomy)), (((bad proportions))), ((extra limbs)), cloned face, (((disfigured))). out of frame, gross proportions, (malformed limbs), ((missing arms)), ((missing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), (((text))), (((signatures))), jpeg artifacts, low quality, lowres, text, watermark, logo, text, letters, username, words, blurry, cropped, small arms, misplaced limbs, incorrect anatomy, deformed limbs, small limbs, small legs, human face, human body, human features",
      // x"text_prompts_1_weight": "-0.9",
      // x"init_image_mode": "IMAGE_STRENGTH",
      // x"image_strength": "0.43",
      // x"cfg_scale": "20",
      // x"clip_guidance_preset": "FAST_BLUE",
      // x"samples": "1",
      // "steps": "50",
      // "style_preset": "neon-punk"
      */}
    </div>
  );
}

export default ImageUpload;
