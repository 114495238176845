import {
  useParams,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
// import logo from "./logo.svg";
import "./App.css";
import "./components/ImageUpload";
import ImageUpload from "./components/ImageUpload";
// import ImageUploadResizer from "./components/ImageUploadResizer";
import PokemonList from "./components/PokemonList";

function App() {
  const { momo } = useParams();
  console.log(`dtb: ${momo}`);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const momoParam = query.get("momo");

  console.log(`dtb: momoParam: ${momoParam}`);

  const showApp = momoParam != null ? momoParam === "theaibot" : false;

  console.log("dtb:env: ", process.env);

  // Local: NODE_ENV: "development";
  // REACT_APP_VERCEL_ENV: "preview";
  // REACT_APP_VERCEL_ENV: "production";

  const isVercelProduction = process.env.REACT_APP_VERCEL_ENV
    ? process.env.REACT_APP_VERCEL_ENV === "production"
    : false;
  const isVercelPreview = process.env.REACT_APP_VERCEL_ENV
    ? process.env.REACT_APP_VERCEL_ENV === "preview"
    : false;

  const isDevelopment =
    process.env.NODE_ENV === "development" || isVercelPreview;
  const isProduction = isVercelProduction;

  console.log(`isVercelProduction: ${isVercelProduction}`);
  console.log(`isVercelPreview: ${isVercelPreview}`);
  console.log(`isDevelopment: ${isDevelopment}`);
  console.log(`isProduction: ${isProduction}`);

  return (
    <div className="App">
      <header className="App-header">
        {showApp && <ImageUpload />}

        {!showApp && isDevelopment && <PokemonList />}
        {!showApp && isProduction && <div>momobot.ai</div>}

        {/* {showApp && <ImageUploadResizer />} */}
      </header>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
export default AppWrapper;
